<script setup lang="ts">
import { MazRadioButtons } from 'maz-ui/components';
import { ref, watch } from 'vue';

const props = defineProps({
  context: Object,
});

const selected = ref()

watch(selected, (value) => {
  props.context.node.input(value);
})

</script>

<template>
  <div class="rate">
    <MazRadioButtons v-model="selected" :options="props.context.options ?? []"/>
  </div>
</template>

<style scoped lang="scss">
</style>